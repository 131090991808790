import { CloseCircleOutlined } from '@ant-design/icons'
import { Col, Divider, Row, Spin, Card, Typography, Form, Input, Space, Button, Switch } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React, { useEffect, useState } from 'react'
import GetIgDataFromUsername from "../Common/ApiCall/GetIgDataFromUsername";
import ApiError from '../Common/Models/ApiError';
import { BadNotif, SuccessNotif } from '../Common/Utils/SendNotification';
import ContentWrapper from '../Components/ContentWrapper'
import PlainContentWrapper from '../Components/PlainContentWrapper'
import CreateTask from '../Common/ApiCall/CreateTask'

const {Title} = Typography
const {Meta} = Card

const ExtractUsers = ({setKey}) => {
  const [load, setLoad] = useState(false)
  const [userObj, setUserObj] = useState(null)
  const [username, setusername] = useState('')

  useEffect(()=>{
    setKey()
  }, [])

  function resetUser() {
    setUserObj(null)
    setusername('')
  }

  function createTask(tasks) {
    console.log(userObj.id, userObj.username, tasks)
    CreateTask(userObj.id, userObj.username, tasks)
      .then(r => {
        setLoad(false)
        resetUser()
        if(r.success) {
          SuccessNotif('Task Created Successfully.')
        } else {
          BadNotif(r)
        }
      })
  }

  async function makeDetailedUserFromUsername(q) {
    setLoad(true)
    let userData = await GetIgDataFromUsername(q);
    setLoad(false)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        BadNotif(null, "Instagram Username does not exist")
      } else {
        BadNotif(null, "Something Went Wrong")
      }
    } else {
      setUserObj(userData)
    }
  }

  return(
    <PlainContentWrapper>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 24}}>
          <ContentWrapper marginLess>
            <Title level={3} style={{fontWeight: 'medium'}}>
              Extract User
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            {load && <Spin />}
            <Row gutter={24} justify="center">
              <Col md={{span: 20}} lg={{span: 14}}>
                {userObj ? (
                  <ConfirmCard 
                    userObj={userObj}
                    resetUser={resetUser}
                    createTask={createTask}
                  />
                ) : (
                  <GetIgUser 
                    setUsername={setusername}
                    username={username}
                    makeDetailedUserFromUsername={makeDetailedUserFromUsername}
                  />
                )}
              </Col>
            </Row>
          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

const ConfirmCard = ({userObj, resetUser, createTask}) => {
  const [form] = Form.useForm()

  function getTasksType(values) {
    return [values.followers ? "FOLLOWERS" :null, values.following ? "FOLLOWING" : null].filter(Boolean)
  }

  function submit() {
    createTask(getTasksType(form.getFieldsValue()))
  }

  return(
    <div>
      <Card
        style={{width: 300, marginTop: 16}}
        actions={[
          <CloseCircleOutlined onClick={resetUser} key="remove" />,
          <Button onClick={submit} key="confirm" >
            Confirm
          </Button>
        ]}
      >
        <Meta 
          avatar={
            <Avatar 
              userObj={userObj.picture}
            />
          }
          title={userObj.username}
        />
      </Card>
      <Form
        form={form}
      >
        <Form.Item
          name="followers"
          label="Followers"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Following"
          name="following"
        >
          <Switch />
        </Form.Item>
      </Form>
    </div>
  )
}

const GetIgUser = ({setUsername, username, makeDetailedUserFromUsername}) => {

  return(
    <Space
      direction="vertical"
    >
      <Title level={4}>
        Enter Instagram Username to Extract
      </Title>
      <Input 
        placeholder="johndoe"
        size="large"
        value={username}
        onChange={e=>setUsername(e.target.value.trim())}
        onPressEnter={()=>{username.length && makeDetailedUserFromUsername(username)}}
      />
      <Button
        disabled={!username.length}
        onClick={()=>makeDetailedUserFromUsername(username)}
        size="large"
        type="primary"
        block
      >
        Continue
      </Button>
    </Space>
  )
}

export default ExtractUsers