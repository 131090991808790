import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl} from "./ApiUrl";
import authHeader from './auth-header'

const GetTasks = async (user) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/tasks"), {
      user
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: [{
      id: 1,
      category: {id: 1, name: "Follower of Abhnv_rai"},
      date_time_submitted: 1234567890,
      progress: {done: 1200, total: 1500}
    },
    {
      id: 2,
      category: {id: 2, name: "Following of Abhnv_rai"},
      date_time_submitted: 1234567890,
      progress: {done: 1500, total: 1500}
    }
  ],
    success: true,
    errors: []
  }
}

export default GetTasks
