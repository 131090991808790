import { Button, Card, Checkbox, Col, Input, InputNumber, Row, Select, Space, Spin, Switch } from 'antd'
import {Form} from 'antd'
import React from 'react'

const bools = [
  {
    value: true,
    option: 'Yes'
  },
  {
    value: false,
    option: 'No'
  }
]

const ViewUserFilter = ({categories, init, filterUsers, filters, initialFields}) => {
  const [form] = Form.useForm()

  function submit() {
    filterUsers(form.getFieldsValue())
  }

  function reset() {
    form.resetFields()
  }

  function clear() {
    filterUsers(initialFields)
    form.setFieldsValue(initialFields)
  }

  if(init) {
    return <Spin />
  }

  return(
    <Card>
      <Form
        form={form}
        layout="horizantal"
        initialValues={filters}
        onFinish={filterUsers}
      >
        <Row futter={24}>
          <Col span={12}>
            <Form.Item
              label="Is Email There" 
              name="is_email_there"
            >
              <Select style={{maxWidth: '100px'}}>
                {bools.map((bool) => (
                  <Select.Option value={bool.value}>
                    {bool.option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Is Contact There"
              name="is_contact_there"
            >
              <Select style={{maxWidth: '100px'}}>
                {bools.map((bool) => (
                  <Select.Option value={bool.value}>
                    {bool.option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Category"
          name="category_id"
        >
          <Select
            placeholder="Select a Category"
            style={{maxWidth: '400px'}}
          >
            {categories.map((category) => (
              <Select.Option
                value={category.id}
                key={category.id}
                title={category.name}
              >
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="followers_greater_than"
              label="Followers Greater Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="followers_less_than"
              label="Followers Less Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
              <Space>
                <Button type="primary" onClick={submit}>
                  Update Filters
                </Button>
                <Button onClick={reset}>
                  Reset
                </Button>
                <Button type="dashed" onClick={clear}>
                  Clear Filters
                </Button>
              </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default ViewUserFilter