class UserDetails {
  constructor(data) {
    let user = data.graphql.user;
    this.id = user.id;
    this.username = user.username;
    this.picture = user.profile_pic_url;
    this.name = user.full_name;
  }
}

export default UserDetails;
