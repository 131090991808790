import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl} from "./ApiUrl";
import authHeader from './auth-header'

const CreateTask = async (username, ig_id, task_type) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/task"), {
      username, 
      ig_id,
      task_type
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: null,
    success: true,
    errors: []
  }
}

export default CreateTask
