import { Button, Col, Divider, Modal, Pagination, Row, Space, Table, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import GetCategories from '../../Common/ApiCall/GetCategories'
import GetUsers from '../../Common/ApiCall/GetUsers'
import ContentWrapper from '../../Components/ContentWrapper'
import EditUser from './EditUser'
import UpdateUser from '../../Common/ApiCall/UpdateUser'
import ViewUserFilter from './ViewUserFilter'
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification'

const {Title} = Typography

const initialFields = {
  is_email_there: null,
  is_contact_there: null,
  category_id: null,
  followers_greater_than: null,
  followers_less_than: null
}

const PAGE_SIZE_OPTIONS = ["5", "10", "25", "50", "100"]

const ViewUsers = ({setKey}) => {
  const [load, setLoad] = useState(true)
  const [init, setInit] = useState(true)
  const [users, setUsers] = useState([])
  const [categories, setcategories] = useState([])
  const [editing, setediting] = useState(false)
  const [edUser, setEdUser] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [totalItems, setTotalItems] = useState(0)
  const [filters, setFilters] = useState(initialFields)

  const columns = [
    {
      id: 'name',
      dataIndex: 'full_name',
      title: "Full Name"
    },
    {
      id: 'username',
      dataIndex: 'username',
      title: 'Username'
    },
    {
      id: 'phone',
      dataIndex: 'phone',
      title: 'Phone'
    },
    {
      id: 'engagement_rate',
      dataIndex: 'engagement_rate',
      title: 'Engagement Rate'
    },
    {
      id: 'category',
      dataIndex: 'category',
      title: 'Category',
      render: (categories) => (
        <>
          {categories.map(category => {
            return (
              <Tag key={category.id}>
                {category.name}
              </Tag>
            )
          })}
        </>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (item) => (
        <Space size="middle">
          <Button type="primary" onClick={()=>{setEdUser(item);setediting(true)}} >
            View / Edit
          </Button>
        </Space>
      )
    }
  ]

  function closeModal() {
    setediting(false)
  }

  function saveUser(values) {
    setLoad(true)
    UpdateUser(values)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif("User Updated")
        } else {
          BadNotif(r)
        }
        getUsers()
      })
  }

  function filterBool(val) {
    return Object.fromEntries(Object.entries(val).filter(([_, v]) => v != null))
  }

  function getCategories() {
    setInit(true)
    GetCategories()
    .then(r => {
      setInit(false)
      if(r.success) {
        setcategories(r.data)
      }
    })
  }

  function getUsers(optValues = {}, pg_size = pageSize, cp = currentPage) {
    setLoad(true)
    optValues.page_size = pg_size
    optValues.page_number = cp
    let values = filterBool(optValues)
    console.log(values)
    GetUsers(values)
      .then(r => {
        setLoad(false)
        if(r.success) {
          setUsers(r.data.users)
          setTotalItems(r.data.count)
        }
      })
  }

  function filterUsers(values) {
    setFilters(values)
    getUsers(values)
  }

  function changePage(page, size) {
    setCurrentPage(page);
    setPageSize(size);
    getUsers({}, size, page)
  }

  useEffect(()=>{
    setKey()
    getCategories()
    getUsers()
  }, [])

  return(
    <ContentWrapper 
      marginLess
      plain
    >
      <Modal
        visible={editing}
        title="View/Edit User"
        okText="Update"
        footer={null}
        onCancel={closeModal}
        width={700}
      >
        <EditUser 
          user={edUser}
          closeModal={closeModal}
          update={saveUser}
        />
      </Modal>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 24}}>
          <ContentWrapper marginLess>
            <Title level={3} style={{fontWeight: 'medium'}}>
              Users
            </Title>
            <Space
              direction="vertical"
              style={{width: '100%'}}
            >
              <ViewUserFilter 
                categories={categories}
                init={init}
                filterUsers={filterUsers}
                filters={filters}
                initialFields={initialFields}
              />
              <div className="is-pulled-right">
                <Pagination 
                  current={currentPage}
                  pageSize={pageSize}
                  pageSizeOptions={PAGE_SIZE_OPTIONS}
                  showSizeChanger
                  total={totalItems}
                  onChange={changePage}
                />
              </div>
              <Table 
                pagination={false && {
                  position: 'topRight',
                  onChange: (page, size) => {setCurrentPage(page);setPageSize(size);},
                  current: currentPage,
                  pageSize: pageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showSizeChanger: true,
                  total: totalItems
                }}
                bordered
                size="middle"
                loading={load}
                columns={columns}
                dataSource={users}
              />
            </Space>
          </ContentWrapper>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default ViewUsers