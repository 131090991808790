import { Button, Form, Input, Space } from 'antd'
import React from 'react'

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const EditUser = ({user, closeModal, update}) => {

  return(
    <Form
      initialValues={user}
      onFinish={update}
    >
      <Form.Item
        {...formItemLayout}
        label="Username"
        name="username"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Full Name"
        name="full_name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formTailLayout}
      >
        <Space>
          <Button htmlType="submit" type="primary">
            Update
          </Button>
          <Button onClick={closeModal}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default EditUser