import React, { useEffect, useState } from 'react'
import GetTasks from '../Common/ApiCall/GetTasks'
import ContentWrapper from '../Components/ContentWrapper'
import PlainContentWrapper from '../Components/PlainContentWrapper'
import { Col, Row, Typography, Divider, Table, Progress, Tooltip } from 'antd'

const {Title} = Typography

const AllProcesses = ({setKey}) => {
  const [tasks, setTasks] = useState([])
  const [load, setLoad] = useState(false)

  function renderDate(epoch) {
    let d = new Date(0)
    d.setUTCSeconds(epoch)
    d = d.toISOString().split('T')[0]
    console.log(d)
    return d
  }

  const columns = [
    {
      id: 'category',
      dataIndex: 'category',
      title: 'Type',
      render: (category) => category.name
    },
    {
      id: 'datetime',
      dataIndex: 'date_time_submitted',
      title: 'Date Submitted',
      render: (date) => renderDate(date)
    },
    {
      id: 'progress',
      dataIndex: 'progress',
      title: 'Progress So Far',
      render: (progress) => (
        <Tooltip title={progress.done + " / " + progress.total }>
          <Progress percent={progress.done*100/progress.total} />
        </Tooltip>
      )
    }
  ]

  function getTasks() {
    setLoad(true)
    GetTasks()
      .then(r => {
        setLoad(false)
        if(r.success) {
          setTasks(r.data)
        }
      })
  }

  useEffect(()=>{
    setKey()
    getTasks()
  }, [])

  return(
    <PlainContentWrapper>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 24}}>
          <ContentWrapper marginLess>
            <Title level={3} style={{fontWeight: 'medium'}}>
              Tasks
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            <Table 
              loading={load}
              columns={columns}
              dataSource={tasks}
            />
          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default AllProcesses